<template>
    <div class="legal text-left">
        <b-container>
            <div class="text-center mb-5">
                <img src="../../assets/svg/buenpuerto-logo.svg" width="240">
            </div>
            <div class="text-right text-sm mb-5">Actualizado a 26 de septiembre de 2022</div>
            <h5 class="bold mb-5">POLÍTICA DE PRIVACIDAD</h5>
            <div class="legal-block">
                <p>
                    En este apartado te informaremos, de la manera más clara posible del uso que hacemos de los datos personales que nos hayas facilitado, con independencia del canal o medio a través del cual interactúes con SOMOS BUEN PUERTO.
                </p>
                <p>
                    A través del presente documento SOMOS BUEN PUERTO informa a los usuarios del cumplimiento de la normativa de protección de datos en vigor; concretamente de:
                </p>
                <ul>
                    <li>Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016 relativo a la protección de las personas físicas, en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos (en adelante, RGPD).</li>
                    <li>Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y Garantía de los Derechos Digitales (en adelante, LOPD).</li>
                </ul>
                <p>
                    La presente política de privacidad estará disponible siempre a través de la Web para que puedas consultarla cuando consideres oportuno, y además y siempre y cuando te solicitemos datos de carácter personal con una finalidad específica, te informaremos de manera detallada del tratamiento concreto que en cada caso llevaremos a cabo con tus datos.
                </p>
                <p>
                    A continuación, te ofrecemos la información relativa al tratamiento de datos de carácter personal por parte de SOMOS BUEN PUERTO.
                </p>
            </div>
            <div class="legal-block">
                <div class="title">
                    ¿Quién es el Responsable del Tratamiento de los datos?
                </div>
                <p>
                    Los datos personales en su caso facilitados serán tratados por BUEN PUERTO RH, S.L (en adelante, SOMOS BUEN PUERTO), cuyos datos de contacto son los siguientes:
                </p>
                <p>
                    Domicilio: Altos del club de polo, 32. 28692 Villanueva de la cañada (Madrid)<br>
                    Número de Identificación Fiscal: B72488927<br>
                    Correo electrónico a efectos de protección de datos: <a href="mailto:lopd@somosbuenpuerto.com">lopd@somosbuenpuerto.com</a>
                </p>
            </div>
            <div class="legal-block">
                <div class="title">
                    ¿Para qué tratamos los datos? ¿Qué datos tratamos? ¿Por qué los tratamos? Y... ¿Hasta cuándo?
                </div>
                <p>
                    Los datos personales serán tratados con diferentes finalidades, dependiendo del motivo por el que fueron facilitados y de la relación que, en su caso, tengamos.
                </p>
                <p>
                    Las principales finalidades para las que podemos tratar tus datos son:
                </p>
                <ol>
                    <li>
                        <p class="bold">
                            Gestionar las relaciones comerciales existentes entre SOMOS BUEN PUERTO las empresas que utilizan nuestros servicios para encontrar candidatos que cubran puestos vacantes en su organización y que hayan utilizado el formulario para Empresas ubicado en la Web o hayan contactado con nosotros a través de empresas@somosbuenpuerto.com
                        </p>
                        <p>
                            Si eres una empresa que ha utilizado los servicios de SOMOS BUEN PUERTO, trataremos tus datos para gestionar la relación que nos une, esto es, para informarnos sobre la existencia de perfiles laborales que se hayan registrado en nuestra Web y que puedan ser válidos para cubrir puestos vacantes en tu organización.
                        </p>
                        <p>
                            En estos casos, los datos que trataremos son los identificativos (nombre, apellidos, dirección de correo electrónico, empresa, teléfono…) y los datos económicos, financieros o bancarios, necesarios para gestionar fundamentalmente la facturación/ pago de servicios y todas las obligaciones fiscales asociadas a la relación.
                        </p>
                        <p>
                            Dichos datos serán utilizados para:
                        </p>
                        <ol type="a">
                            <li>
                                Contactar contigo para ofrecerte perfiles de candidatos que puedan ser aptos para cubrir una vacante en tu empresa.
                            </li>
                            <li>
                                Contactar contigo en aquellos casos en que exista una incidencia o comunicación relevante relativa a SOMOS BUEN PUERTO o/y al servicio que se te presta y/o a la relación negocial existente.
                            </li>
                            <li>
                                Gestionar el pago de los servicios y con fines de facturación
                            </li>
                            <li>
                                Enviar encuestas que nos permitan conocer tu grado de satisfacción con SOMOS BUEN PUERTO, y con ello, mejorar nuestros servicios.
                            </li>
                        </ol>
                        <p>
                            La legitimación para los tratamientos de datos especificados en los apartados a), b) y c) se encuentran amparados en el consentimiento prestado por la empresa cuando contacta con nosotros. El tratamiento d) tiene su base en el interés legítimo de SOMOS BUEN PUERTO.
                        </p>
                        <p>
                            Estos datos serán conservados mientras la relación comercial que nos une permanezca vigente.
                        </p>
                        <p>
                            Te informamos igualmente de que dichos datos podrán ser comunicados a terceros involucrados en el desarrollo de la relación existente con el fin exclusivo de que éstos puedan ejecutar correctamente las funciones que tengan asignadas (entidades financieras, entidades de detección y prevención de fraude, proveedores de servicios tecnológicos, proveedores y colaboradores de servicios de logística, transporte y entrega, proveedores de servicios de gestoría/asesoría financiera de SOMOS BUEN PUERTO, proveedores y colaboradores de servicios relacionados con marketing y publicidad) y durante el tiempo estrictamente necesario para realizar su labor, no siendo tratados por estos ni conservados más allá de lo aquí definido.
                        </p>
                        <p>
                            No se realizarán cesiones fuera de los supuestos previstos (salvo las exigidas por obligación legal) ni se prevén transferencias internacionales.
                        </p>
                    </li>
                    <li>
                        <p class="bold">
                            Gestionar la participación de usuarios en procesos de selección de empresas que hayan solicitado nuestros servicios cuando se hayan registrado a través de nuestro formulario Web de Talento o cuando hayan contactado para ello con nosotros a través de <a href="mailto:talento@somosbuenpuerto.com">talento@somosbuenpuerto.com</a>.
                        </p>
                        <p>
                            En este caso, además de los datos identificativos facilitados ya sea a través del formulario Web o a través de correo electrónico (nombre, apellidos, correo electrónico y teléfono), se tratarán los que consten en el mismo, esto es, datos académicos y profesionales tales como formación/titulaciones, historial de estudiante, experiencia profesional, pertenencia a colegios o asociaciones profesionales...
                        </p>
                        <p>
                            Tales datos serán tratados según lo indicado por SOMOS BUEN PUERTO para ofrecer puestos de trabajo de empresas que están buscando candidatos para sus vacantes. Dicho tratamiento se justifica en el propio consentimiento aportado por el usuario que se registra o remite su CV a SOMOS BUEN PUERTO. El usuario podrá retirar su consentimiento en cualquier momento, pero en ese caso, SOMOS BUEN PUERTO no podrá tener en cuenta ese currículum para valorar su adecuación en futuros procesos de selección de personal que se gestionen por dicha empresa.
                        </p>
                        <p>
                            En cualquier caso, y si lamentablemente SOMOS BUEN PUERTO no ha contactado con el usuario para ofrecer alguna oportunidad de trabajo una vez transcurridos dos años desde que se produjo el envío del currículum, ésta procederá a eliminar tus datos según nos indica la ley vigente.
                        </p>
                    </li>
                    <li>
                        <strong>Cumplir con las obligaciones legales exigidas a SOMOS BUEN PUERTO, así como realizar estudios estadísticos</strong> que nos permitan diseñar mejoras en los servicios prestados en base a nuestro interés legítimo.
                    </li>
                </ol>
                <p>
                    Asimismo, SOMOS BUEN PUERTO informa de que a través de <a href="https://www.linkedin.com/company/buenpuerto/?viewAsMember=true" target="_blank">Linkedin</a> podrán publicarse eventos, campañas o cualquier otro tipo de información publicitaria sobre los servicios que ofrece o las acciones que realiza, aceptando el usuario ser destinatario de dicha información por el mero hecho de ser, en su caso, un contacto de SOMOS BUEN PUERTO en dicha red social. Linkedin se rige por lo dispuesto en sus <router-link :to="{ name: 'privacidad' }" target="_blank" class="black">condiciones de uso y política de privacidad</router-link>. Puedes consultar los enlaces para conocer sus políticas.
                </p>
                <p>
                    Conservaremos tus datos durante el tiempo estrictamente necesario para cumplir con cada una de las finalidades determinadas. Finalizados estos plazos, tus datos serán conservados y protegidos debidamente durante el tiempo en que pudieran surgir responsabilidades derivadas del tratamiento, en cumplimiento con la normativa vigente en cada momento. Una vez prescriban las posibles acciones en cada caso, procederemos a la supresión de tus datos.
                </p>
            </div>
            <div class="legal-block">
                <div class="title">
                    ¿Dónde almacenamos los datos?
                </div>
                <p>
                    Los datos personales facilitados serán almacenados utilizando las adecuadas garantías de protección exigidas por la normativa de aplicación.
                </p>
            </div>
            <div class="legal-block">
                <div class="title">
                    ¿Qué derechos tienes relacionados con el tratamiento de tus datos?
                </div>
                <p>
                    Como titular de los datos que tratamos, puedes según lo previsto en el RGPD:
                </p>
                <ul>
                    <li>
                        acceder a tus datos personales
                    </li>
                    <li>
                        solicitar la rectificación de los datos que sean inexactos
                    </li>
                    <li>
                        solicitar la supresión de tus datos
                    </li>
                    <li>
                        solicitar la limitación del tratamiento de tus datos
                    </li>
                    <li>
                        oponerte al tratamiento de tus datos
                    </li>
                    <li>
                        solicitar su portabilidad
                    </li>
                    <li>
                        presentar una reclamación ante la Agencia Española de Protección de Datos, si consideras que el tratamiento que estamos haciendo de tus datos no es adecuado a la normativa o en el caso de no ver satisfecho el ejercicio de alguno de tus derechos
                    </li>
                </ul>
                <p>
                    Para ejercer cualquiera de estos derechos, puedes dirigirte por escrito al domicilio social de SOMOS BUEN PUERTO incluido en el presente documento o enviar un correo electrónico a <a href="mailto:lopd@somosbuenpuerto.com">lopd@somosbuenpuerto.com</a>, indicando el derecho que quieres ejercer y adjuntando copia de tu DNI o cualquier otro documento identificativo válido a estos efectos.
                </p>
                <p>
                    Tal y como te hemos indicado en el Aviso Legal, eres el único responsable de que la información que nos proporciones, a través de cualquier medio habilitado para ello, sea cierta. Esto significa que si modificas p. ej. tu domicilio (o cualquier otro dato), será necesario que actualices esta información, poniéndote en contacto con nosotros, de manera que la información que conste en nuestro sistema se corresponda siempre con tu situación real. Si no procedes de este modo, serás el responsable de los perjuicios que puedas causarnos.
                </p>
                <p>
                    No queremos que nos proporciones datos de terceros. Si te ves obligado a hacerlo y no ha sido posible que sea el propio titular de los datos el que nos los facilite, te comprometes a haber obtenido previamente su consentimiento para que sus datos sean tratados por SOMOS BUEN PUERTO, debiendo haberles informado previamente de todo lo previsto en el artículo 14 del RGPD.
                </p>
            </div>
            <div class="legal-block">
                <div class="title">
                    ¿Qué medidas de seguridad aplicamos para proteger tus datos?
                </div>
                <p>
                    Nunca compartiremos tus datos con terceros sin haber obtenido tu previo consentimiento, salvo obligación legal.
                </p>
                <p>
                    Te garantizamos la seguridad y confidencialidad de tus datos de carácter personal. Te informamos que hemos implantado las medidas de seguridad de índole técnica y organizativa necesarias para evitar la alteración, pérdida, tratamiento o acceso no autorizado de tus datos.
                </p>
                <p>
                    Siempre consideraremos los siguientes principios en el tratamiento de tus datos:
                </p>
                <ul>
                    <li>
                        Principio de licitud, lealtad y transparencia: solicitaremos tu consentimiento de manera clara y transparente de los fines específicos para los que se tratarán y de cualesquiera otras informaciones requeridas por la normativa. Cualquier formulario de recogida de datos, contará con la pertinente leyenda informativa.
                    </li>
                    <li>
                        Principio de minimización de datos: únicamente te solicitaremos y trataremos aquellos datos estrictamente necesarios para cumplir con la finalidad que te hemos indicado. Si p. ej., necesitamos tus datos para facturar un servicio, no se nos ocurrirá preguntar por tu estado civil o tu fecha de nacimiento.
                    </li>
                    <li>
                        Principio de limitación del plazo de conservación: tos datos serán conservados durante no más tiempo del necesario para los fines del tratamiento, en función a la finalidad. En cada recogida de datos, te informaremos del plazo de conservación que aplique.
                    </li>
                    <li>
                        Principio de integridad y confidencialidad: Tus datos se tratarán garantizando una seguridad y confidencialidad adecuada.
                    </li>
                </ul>
            </div>
            <div class="legal-block">
                <div class="title">
                    Modificaciones en la Política de Privacidad
                </div>
                <p>
                    Podemos modificar la presente Política de Privacidad para adaptarla a novedades legislativas, jurisprudenciales, y a prácticas o decisiones adoptadas por la Agencia Española de Protección de Datos o el Grupo Europeo de Protección de Datos del Artículo 29. Si esto sucede, lo advertiremos en la Web o a través de los medios que consideremos más apropiados para el caso y destacaremos de manera sencilla cuáles han sido los cambios introducidos.
                </p>
                <p>
                    En cualquier caso, te recomendamos revisar la presente política, disponible de manera permanente en nuestra Web en cada una de tus visitas. Si hay cambios, la fecha de actualización será modificada.
                </p>
            </div>
        </b-container>
    </div>
</template>

<script>
//import NavBar from '@/components/NavBar'

export default {
    name: 'AvisoLegal',
    components: {
    },
    methods: {
        getLink(route) {
            return `<a href="${window.location.origin}/${route}">${window.location.origin}/${route}</a>`
        }
    }
}
</script>